import styles from '../../styles/features/titleContainer.module.css'
import appStoreIcon from '../../assets/appStore.png'
import playStoreIcon from '../../assets/playStore.png'

function TitleContainer({}) {
  return (
    <div className={styles.main}>
      <text className={styles.title}>
        ShoreCasts centralizes the tools needed for planning your days on the water. Explore tides, weather, marinas, tackle shops, and boat ramps along the entire US coastline.
      </text>
      <div className={styles.lowerSection}>
        <img className={styles.icon} src={appStoreIcon}/>
        <img className={styles.icon} src={playStoreIcon}/>
      </div>
    </div>
  )
}
export default TitleContainer